/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/algoliasearch@4.24.0/dist/algoliasearch-lite.umd.js
 * - /npm/animejs@3.2.0/lib/anime.min.js
 * - /npm/katex@0.12.0/dist/contrib/copy-tex.min.js
 * - /npm/frappe-charts@1.5.0/dist/frappe-charts.min.iife.min.js
 * - /npm/whatwg-fetch@3.4.0/dist/fetch.umd.min.js
 * - /npm/instantsearch.js@4.74.1/dist/instantsearch.production.min.js
 * - /npm/lozad@1.16.0/dist/lozad.min.js
 * - /npm/pace-js@1.0.2/pace.min.js
 * - /npm/pjax@0.2.8/pjax.min.js
 * - /npm/quicklink@2.3.0/dist/quicklink.umd.js
 * - /gh/amehime/MiniValine@4.2.2-beta10/dist/MiniValine.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
